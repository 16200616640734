<template>
  <div class="container-fluid my-auto register-card text-center">
    <h3 class="text">Create Account</h3>
    <hr>
    <form @submit.prevent="submitForm">
      <div class="text-start text-secondary mb-3 row input-container">
        <input type="text" name="firstname"
        class=" fs-6 input-field" v-model="user.firstName"
        placeholder="First Name"
        :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"/>
        <span class="input-border"></span>
        <div v-if="submitted && !$v.user.firstName.required"
        class="form-text error">First Name is required</div>
      </div>
      <div class="text-start text-secondary mb-3 row input-container">
        <input type="text" name="lastname"
        class=" fs-6 input-field" v-model="user.lastName" placeholder="Last Name"
        :class="{ 'is-invalid': submitted && $v.user.lastName.$error }"/>
        <span class="input-border"></span>
        <div v-if="submitted && !$v.user.lastName.required"
        class="form-text error">Last Name is required</div>
      </div>
      <div id="email-field" class="text-start text-secondary mb-3 row input-container">
        <input @blur="checkUser" @focus="emailEntry=false" type="text" name="email"
        placeholder="Email" class=" fs-6 input-field" v-model="user.userEmail"
        :class="{ 'is-invalid': submitted && $v.user.userEmail.$error,
        'user-valid': userValid && emailEntry && !$v.user.userEmail.$error,
        'user-invalid': emailEntry && !$v.user.userEmail.$error }">
        <span class="input-border"></span>
        <div v-if="submitted && !$v.user.userEmail.required" class="form-text error">
          Email is required</div>
        <div v-if="submitted && !$v.user.userEmail.email" class="form-text error">
        Email is invalid</div>
        <div v-show="emailEntry && !userValid && !$v.user.userEmail.$error"
        class="error">
          <small>
            This email is already been registered, please sign in
            <router-link class= "text-danger text-underline" to="/login">here</router-link>
          </small>
        </div>
      </div>
      <div class="text-start text-secondary mb-3 row input-container">
        <input type="password" name="password" placeholder="Password"
        class=" fs-6 input-field" v-model="user.password"
        :class="{ 'is-invalid': submitted && $v.user.password.$error }"/>
        <span class="input-border"></span>
        <div v-if="submitted && !$v.user.password.required" class="form-text error">
        Password is required</div>
        <div v-if="submitted && !$v.user.password.minLength" class="form-text error">
        Password must be at least 8 characters long</div>
        <div v-if="submitted && !$v.user.password.valid && user.password"
        class="form-text error">
        Password must contain Uppercase and lowercase characters, numbers and symbols</div>
      </div>
      <div class="text-start text-secondary mb-3 row input-container">
        <input type="password" name="confirmpassword" placeholder="Confirm Password"
        class=" fs-6 input-field" v-model="user.confirmPassword"
        :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }"/>
        <span class="input-border"></span>
        <div v-if="submitted && !$v.user.confirmPassword.required"
        class="form-text error">Confirm Password is required</div>
        <div v-if="submitted && !$v.user.confirmPassword.sameAsPassword"
        class="form-text error">Passwords do not match</div>
      </div>
      <div class="mb-3 text-start">
        <span class="col-form-label me-3">I am a: </span>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="studentRadio"
          value="1" v-model="user.role">
          <label class="form-check-label" for="studentRadio">Student</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="teacherRadio"
          value="2" v-model="user.role">
          <label class="form-check-label" for="teacherRadio">Teacher</label>
        </div>
      </div>
 <!--      <div class="form-check form-text text-start">
        By signing up, you agree to our <a class="text-start text-primary fw-light form-text"
        id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
      </div> -->
     <!--  <div class="bg-gradient-primary float-end">
        <button type="submit" class="btn bg-pri mt-2 mx-2"
        @click="submitForm">Sign Up
        </button>
      </div>
      <div class="form-check form-text text-start">
        By signing up, you agree to our <a class="text-start text-primary fw-light form-text"
        id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
      </div>
      <div>
        <button @click="checkGoogleUser" class="social-button">
          <img alt="Google Logo" src="../assets/btn_google_signin_light_normal_web.png">
        </button>
      </div> -->
      <div class="row">
        <div class="col text-start">
          <p class="mb-0 form-text">Already have an account?</p>
          <router-link to="/login" class="float-start my-0 text-primary fw-light form-text">
           Log In</router-link>
        </div>
        <div class="col bg-gradient-primary">
          <button type="submit"
          class=" float-end btn bg-pri my-2 text-center text-white">
            Sign Up
          </button>
        </div>
      </div>
    </form>
<!--     <p class="fs-5"> or
      <button @click="checkGoogleUser" class="social-button mt-2 p-0">
        <img alt="Google Logo" src="../assets/btn_google_signin_light_normal_web.png">
      </button>
    </p> -->
    <div class="form-check form-text text-center">
      By signing up, you agree to our <a class="text-primary fw-light form-text"
      id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
// import firebase from 'firebase';

export default {
  name: 'Register',
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        userEmail: '',
        password: '',
        confirmPassword: '',
        role: 1,
      },
      submitted: false,
      googleSubmitted: false,
      invalid: null,
      userValid: null,
      emailEntry: false,
      userData: {},
    };
  },
  validations: {
    user: {
      firstName: { required },
      lastName: { required },
      userEmail: { required, email },
      password: {
        required,
        minLength: minLength(8), // I assume you'd want something like this too
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase
            && containsLowercase
            && containsNumber
            && containsSpecial
          );
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    submitForm() {
      this.submitted = true;
      if (this.userValid) {
        this.$v.$touch();
        if (!this.$v.user.$invalid && this.userValid) {
          this.$store.commit('setLoadScreen', { status: true });
          const payload = {
            givenname: this.user.firstName,
            lastname: this.user.lastName,
            email: this.user.userEmail,
            password: this.user.password,
            role: this.user.role,
          };
          axios.post('/api/account/registerUser', payload)
            .then(() => {
              this.sendVerificationEmail();
            })
            .catch(() => {
              // alert(error);
              this.$store.commit('setLoadScreen', { status: false });
            });
        }
      }
    },
    sendVerificationEmail() {
      const encEmail = this.encoder64(this.user.userEmail);
      const session = {
        email: this.user.userEmail,
        name: this.user.firstName,
      };
      session.verificationUrl = `${window.location.origin}/verify/${encEmail}`;
      axios.post('/api/account/sendVerification', session)
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.redirectTimer();
        });
    },
    encoder64(param) {
      const encoded = btoa(param);
      return encoded;
    },
    checkUser() {
      try {
        this.$v.user.userEmail.$touch();
        const payload = {
          email: this.user.userEmail,
        };

        axios.post('/api/checkUser', payload)
          .then((res) => {
            this.emailEntry = true;
            if (res.data.valid === 1) {
              this.userValid = true;
            } else {
              this.userValid = false;
            }
          });
      } catch (error) {
        console.error(error.response);
      }
    },
    loginRedirect() {
      clearInterval(this.interval);
      this.$router.push('/login');
    },
    redirectTimer() {
      this.interval = setInterval(() => {
        if (this.redirectTime > 0) {
          this.redirectTime -= 1;
        } else {
          this.loginRedirect();
        }
      }, 1000);
    },
  },
  mounted() {
    // this.gettiers();
  },
};
</script>

<style scoped>
</style>
